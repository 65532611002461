<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-alert
          v-model="alert"
          color="#fdf0d7"
          dense
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
        >Atribua actividades aos usário da plataforma. Determine o nível e tipo de informção que cada utilizador deve ter acesso.</v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle">
          <strong>Nível de Acesso</strong>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
            rounded
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          rounded
          text
          outlined
          @click.prevent="dialog = !dialog"
        >
          <v-icon
            left
            color="#F7A400"
          >mdi-plus-circle-outline</v-icon>Novo Nível de Acesso
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="getRoles"
          class="elevation-1"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="blue"
              x-small
              fab
              class="ma-1"
              dark
              @click="showRole(item)"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              x-small
              fab
              class="ma-1"
              dark
              @click="edit(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              x-small
              fab
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add :dialog="dialog" :role="role" :show="show" @close="closeAddDialog()" />
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Nivel de Acesso?" 
      @yes="deleteRole" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-container>
</template>

<script>
import { GET_ROLES_ROOT_QUERY } from './../graphql/Query'
import { DELETE_ROLE_MUTATION } from './../graphql/Mutation'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import Role from '@/models/Role'
import Add from './../components/AddRoleDialog'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'Role',
  mixins: [
    dialogMixins
  ],
  components: {
    Add, ConfirmDialog, ErrorDialog, ProgressDialog, SuccessDialog
  },
  data: () => ({
    alert: true,
    roles: [],
    role: new Role(),
  }),
  apollo: {
    roles: {
      query: GET_ROLES_ROOT_QUERY
    }
  },
  watch: {
    roles: function (val) {
      this.setRoles(val)
    }
  },
  computed: {
    ...mapGetters({
      getRoles: 'role/getRoles'
    }),
    headers () {
      return [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status"
        },
        { text: "Acção", align: "center", value: "actions" }
      ];
    }
  },
  methods: {
    ...mapActions({
      removeRole: 'role/removeRole',
      setRoles: 'role/setRoles',
    }),
    closeAddDialog () {
      this.role = new Role()
      this.show = false
      this.dialog = false
    },
    async deleteRole (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ROLE_MUTATION,
          variables: {
            id
          }
        })
        this.removeRole(id)
        this.success = 'Nivel de acesso removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    edit (role) {
      const { id, description, name, permissions } = role
      const permissionsIds = permissions.map(p => (p.id))
      this.role = { id, description, name, permissionsIds }
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    showRole (role) {
      const { id, description, name, permissions } = role
      const permissionsIds = permissions.map(p => (p.id))
      this.role = { id, description, name, permissionsIds }
      this.show = true
      this.dialog = true
    }
  }
}
</script>